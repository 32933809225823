<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="Add Employee">
        <save-cancel :save-enabled="!validation.pristine && !validation.invalid" @onSave="saveEmployee()" />
      </sticky-header>
    </template>
    <employee-form v-model="employee" />
    <div class="err-msg">{{ validationMessage }}</div>
    <template #bottomContent="{ validation }">
      <save-cancel :save-enabled="!validation.pristine && !validation.invalid" @onSave="saveEmployee()" />
    </template>
  </add-edit-page>
</template>
<script>
import { AddEditPage } from "@/components";
import StickyHeader from "@/components/layout/StickyHeader";
import SaveCancel from "@/components/buttons/SaveCancel";
import EmployeeForm from "@/modules/admin/employees/individuals/EmployeeForm";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";

export default {
  name: "CreateEmployeeView",
  components: { EmployeeForm, SaveCancel, StickyHeader, AddEditPage },
  data: () => {
    return {
      employee: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        role: "",
        locations: [],
      },
      validationMessage:""
    };
  },
  methods: {
    saveEmployee() {
      const param = {
        firstName: this.employee.firstName.trim(),
        lastName: this.employee.lastName.trim(),
        email: this.employee.email.toLowerCase().trim(),
        phone: this.employee.phone.replace(/\D/g, ""),
        role: this.employee.role,
      }
      this.validationMessage = ""
      restApi.post('employee/addEmployee', encodeWithParam(param))
        .then(({data}) => {
          if(!data.success){
            this.validationMessage = data.message;
            return;
          }
          if (this.employee.locations.length === 0) {
            this.$router.push({ name: "employees" });  
          } else {
            let param = {
              employeeId: data.result[0].employeeID,
              locations: this.employee.locations,
              role: this.employee.role 
            }
            restApi.post('employee/addEmployeeLocations', encodeWithParam(param))
              .then(() => {
                this.$router.push({ name: "employees" });      
              });
          }
        })
        .catch(err=>{
          this.validationMessage = err.message;
        })
    },
  },
};
</script>

<style scoped>
  .err-msg{
    color: #c2272c;
  }
</style>
